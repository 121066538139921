import {Routes, Route} from 'react-router-dom';
import {useTypedSelector} from "../hooks/useTypedSelector";
import WorldMap from "../pages/WorldMap";
import Intro from "../pages/Intro";
import Login from "../pages/Login";
import Account from "../pages/Account";
import Researches from "../pages/Researches";
import Deck from "../pages/Deck";
import Shop from "../pages/Shop";
import GamePage from "../pages/GamePage";
const AppRouter = () => {
    const {isAuth} = useTypedSelector(state => state.auth);

  

    return (

        <Routes>

            <Route path="/" element={ isAuth ? <WorldMap/> : <Login/>}/>
            <Route path="/intro" element={ isAuth ?<Intro/> : <Login/>}/>
            <Route path="/gallery" element={ isAuth ?<Researches/> : <Login/>}/>
            <Route path="/deck" element={ isAuth ?<Deck/> : <Login/>}/>
            <Route path="/shop" element={ isAuth ?<Shop/> : <Login/>}/>
            <Route path="/game" element={ isAuth ?<GamePage/> : <Login/>}/>
            <Route path="*" element={ isAuth ?<WorldMap/> : <Login/>}/>

        </Routes>

    );
};

export default AppRouter;
