import {AuthAction, AuthActionEnum, AuthState} from "./types";
import {IUser} from "../../../models/IUser";
import {ITutorial} from "../../../models/ITutorial";
import {ICpuStory} from "../../../models/ICpuStory";
import {IBoxArray} from "../../../models/IBoxArray";


const initialState: AuthState = {
    isAuth: false,
    error: 'no error',
    isLoading: false,
    user: {} as IUser,
    tutorial: {isActive:false, step: 'intro'} as ITutorial,
    cpuStory : {story:null, cpuId:null, cpuNumber:null, action:null} as ICpuStory,
    music: {playMusic: true, playTutorial:true, playMenu:true, playBattle:true},
    boxes: {} as IBoxArray,
    openBox: false,
    promoSocial: false,
    cardBigSize: false,
}

export default function authReducer(state = initialState, action: AuthAction): AuthState {
    switch (action.type) {
        case AuthActionEnum.SET_AUTH:
            return {...state, isAuth: action.payload, isLoading: false}
        case AuthActionEnum.SET_USER:
            return {...state, user: action.payload}
        case AuthActionEnum.SET_TUTORIAL:
                return {...state, tutorial: action.payload}
        case AuthActionEnum.SET_ERROR:
            return {...state, error: action.payload, isLoading: false}
        case AuthActionEnum.SET_IS_LOADING:
            return {...state, isLoading: action.payload}
        case AuthActionEnum.SET_CPU_STORY:
            return {...state, cpuStory: action.payload}
        case AuthActionEnum.SET_MUSIC:
            return {...state, music: action.payload}
        case AuthActionEnum.SET_BOXES:
            return {...state, boxes: action.payload}
        case AuthActionEnum.SET_OPENBOX:
            return {...state, openBox: action.payload}
        case AuthActionEnum.SET_PROMO_SOCIAL:
            return {...state, promoSocial: action.payload}
        case AuthActionEnum.SET_CARD_BIG_SIZE:
            return  {...state, cardBigSize: action.payload}
        default:
            return state;
    }
}
