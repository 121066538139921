import React, {useRef} from 'react';


function UnitAD({attack, hp, level, isBigSize, isVeryBigSize}) {

    const prevHP = useRef(hp);
    const prevAttack = useRef(attack);

    let hpClasses = 'unitAD unitD';
    let attackClasses = 'unitAD unitA';
    let containerSize = ' unitADContainerNormalSize';
    if(isBigSize){
        hpClasses += ' unitAdBig';
        attackClasses += ' unitAdBig';
        containerSize = ' unitADContainerBigSize';
    }

    if(isVeryBigSize){
        containerSize = ' unitADContainerBigSize';
    }

    if (prevHP.current !== hp) {
        hpClasses += ' animate__animated animate__heartBeat';
    }
    prevHP.current = hp;


    if (prevAttack.current !== attack) {
        attackClasses += ' animate__animated animate__heartBeat';
    }
    prevAttack.current = attack;

    return (
        <div className={'unitADContainer unitADContainerBG-' + level + containerSize}>
            <div className={attackClasses}>{attack}</div>
            <div className={hpClasses}>{hp}</div>
        </div>
    );
}

export default UnitAD;